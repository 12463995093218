import Id from '../schemata/Id';
import {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_DIVERSE,
  GENDER_UNKNOWN,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_NOK_MOBILE,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_PERSONAL,
  EMAIL_TYPES,
  PHONE_TYPES,
} from './patient';

// PATIENT
export const VARIABLE_ID__NAME = 'bDaxwY5xutyHikRJ3';
export const VARIABLE_ID__NAME_FIRST = 'GtQgBdLoWPJdDdp5r';
export const VARIABLE_ID__NAME_LAST = 'MwdDGEJS4fzNLLnib';
export const VARIABLE_ID__PHONE = 'Zygevpkh5JvoxS65a';
export const VARIABLE_ID__EMAIL = 'N7bX6fTtD5uwQBK9M';
export const VARIABLE_ID__EMAIL_PERSONAL = 'Mi3FXxcBkmvYiSvpg';
export const VARIABLE_ID__EMAIL_WORK = 'e6HkT847Yehp6AzQt';
export const VARIABLE_ID__EMAILS = 'tjMyXKcCz4qa7ejeh';
export const VARIABLE_ID__AGE = 'nXKK2xzJEGLWYkzRX';
export const VARIABLE_ID__GENDER = 'vnCCpS3y7hrWbM4TB';
export const VARIABLE_ID__GENDER_SINGAPORE = 'yRSbKPitSbiCcbGTY';
export const VARIABLE_ID__ETHNICITY = 'iGjEtL82n6M54KwKf';
export const VARIABLE_ID__ETHNICITY_SINGAPORE = 'popozmitinogikimm';
export const VARIABLE_ID__ETHNICITY_NEW_ZEALAND_AUSTRALIA = 'zGjEtL67n6M62KwKf';
export const VARIABLE_ID__ETHNICITY_NEW_ZEALAND = '7kxqRpspnQFryNiWz';
export const VARIABLE_ID__RECIPIENT_OWNERSHIP = 'frHdEmz4TMeSduXiF';
export const VARIABLE_ID__IDENTIFIERS = 'k3wdJBfsjFzGZDuQK';
export const VARIABLE_ID__IDENTIFIER = 'xkyxb8DYhGbKoqvRq';
export const VARIABLE_ID__NATIONAL_ID = 'rpQx3L3uNWvcokfsS';
export const VARIABLE_ID__PHONE_HOME = 'E6RjNn8GGmRy7rdeS';
export const VARIABLE_ID__PHONE_MOBILE = 'ihagePCtg4nTm9vMP';
export const VARIABLE_ID__PHONE_NOK_MOBILE = '6kvqFnPhMFbPXbwYj';
export const VARIABLE_ID__PHONE_WORK = 'vvEvPfkEoj9f7ZgGX';
export const VARIABLE_ID__PHONES = 'zNy9kwGvTG6HqLsxt';
export const VARIABLE_ID__ADM_VISIT_DATE = 'NR2TDEPx9h9J56iWK';
export const VARIABLE_ID__BIRTH_DATE = '24qwgnRzgkumaPpQh';
export const VARIABLE_ID__BIRTH_YEAR = 'vuet3TfDux3XhJ5nF';
export const VARIABLE_ID__META_FIELD_1 = 'jBMLTarkbTXeJTs7E';
export const VARIABLE_ID__META_FIELD_2 = '3hfQ5pNwM4JnQuTdm';
export const VARIABLE_ID__META_FIELD_3 = 'o5e8swwruvPiWoD3Q';
export const VARIABLE_ID__META_FIELD_NUMBER_1 = 'AasbqCke52ivibe7D';
export const VARIABLE_ID__META_FIELD_NUMBER_2 = '69DNSJD4rokE3pJjN';
export const VARIABLE_ID__META_FIELD_NUMBER_3 = 'JdE34ydQCHp8p5ZKr';
export const VARIABLE_ID__NOK_NRIC = 'CLsoSFtvarocrAMv7';
export const VARIABLE_ID__NOK_NAME = 'B7R88PdoJe2MDsuje';
export const VARIABLE_ID__NOK_RELATIONSHIP = '784KjKCq58tfXcCKs';
export const VARIABLE_ID__LANGUAGE_PREFERENCE = 'ts4p7y5veunxe4LMW';

// MILESTONE
export const VARIABLE_ID__MILESTONE_NAME = 'j3zTQAGmkkZSKT4Lz';

// PROJECT
export const VARIABLE_ID__PROJECT_NAME = 'GTPBKJxDb72pWY2vv';

// PARTICIPATION
export const VARIABLE_ID__PARTICIPATION_OWNERSHIP = 'khSMwfSuz5aFgbntv';
export const VARIABLE_ID__STUDY_NO = 'KKR7qswiJAC76GaFj'; // TODO: Change name to "custom study number"
export const VARIABLE_ID__STUDY_NO_TERMS = 'luWaWopKULtiUdevu';
export const VARIABLE_ID__PARTICIPATION_TRACK_ID = 'QPtfeRYbRC4QCBu6j';
export const VARIABLE_ID__PARTICIPATION_TRACK_DATE = 'bZtHtRToXYQXLrE8t';
export const VARIABLE_ID__PARTICIPATION_TRACK_DATE_DEDUCED_FROM_MILESTONE_SCHEDULE =
  'gDKga5qS4SyFMSw2S';
export const VARIABLE_ID__STATUS_IN_PROJECT = 'GEmp2h5so6Tg9c8pE';
export const VARIABLE_ID__CLINICIAN = 'a4qwgnRzgkumaPpQh';
export const VARIABLE_ID__CASE_MANAGER = '8RFByz2pxSKMAkAXA';
export const VARIABLE_ID__PATIENT_BASELINE = 'tEr464KwqZtvC2Dd3';
export const VARIABLE_ID__PARTICIPATION_BED_NUMBER = 'Jz7cjKJudEn6bZWY7';
export const VARIABLE_ID__PARTICIPATION_CATEGORY = 'Pd4vkqpA2QnPXejdi';
export const VARIABLE_ID__PARTICIPATION_EMR_SYNC = 'hCbkuQbXpmQbF2GZr';
export const VARIABLE_ID__PARTICIPATION_HALL = 'vQNdYzuvyToEFcsBQ';
export const VARIABLE_ID__PARTICIPATION_ROW = 'ZewtdsLFBvZazRvNP';
export const VARIABLE_ID__PARTICIPATION_BED = 'wrZHuMWCWoG5YagNT';

export const VARIABLE_ID__ACTIVITY_STATE = 'CMnEfQKecLaciDiAM';
export const VARIABLE_ID__ACTIVITY_OWNERSHIP = 'L8QaKdWSag8ADDMM3';
export const VARIABLE_ID__ACTIVITY_IDENTIFIERS = '2qDNJ2uas4nFz6kfd';
export const VARIABLE_ID__ACTIVITY_IDENTIFIER = 'QFdSm9wqGdmTM4ycK';
export const VARIABLE_ID__ACTIVITY_MILESTONE_ID = 'EahERcnYDLZc7MuoX';
export const VARIABLE_ID__ACTIVITY_DATE_START = 'Rix4c9S6QHqxZfbSW';
export const VARIABLE_ID__ACTIVITY_DATE_END = '2ycHemF8ySTaBn2zS';
export const VARIABLE_ID__ACTIVITY_TIME_START = '2rRbDp9CGBeWjaFZ7';
export const VARIABLE_ID__ACTIVITY_TIME_END = '5JXLwKaQFPu64vrWL';

export const VARIABLE_WIDGET_TYPE__TEXT = 'text';
export const VARIABLE_WIDGET_TYPE__TEXTAREA = 'textarea';
export const VARIABLE_WIDGET_TYPE__DATE = 'date';
export const VARIABLE_WIDGET_TYPE__RELATIVE_DATE = 'relativeDate';
export const VARIABLE_WIDGET_TYPES = [
  VARIABLE_WIDGET_TYPE__TEXT,
  VARIABLE_WIDGET_TYPE__TEXTAREA,
  VARIABLE_WIDGET_TYPE__DATE,
  VARIABLE_WIDGET_TYPE__RELATIVE_DATE,
];

// const mapToProperty = (name, values) => {
//   return map(values, (value) => {
//     return {
//       [name]: value,
//     };
//   });
// };

const NAMESPACE_DEFAULT_NHI = 'default/national_health_identifier';
const NAMESPACE_DEFAULT_IDENTIFIER = 'default/identifier';

// NOTE: Variable.key will be used to create Recipient object.
const BUILT_IN_VARIABLES = [
  {
    _id: VARIABLE_ID__BIRTH_DATE,
    name: 'Birth date',
    scopeName: '@recipient',
    nativeKey: 'birthDate',
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Date of birth',
      },
    },
  },
  {
    // NOTE: This is variable refers the same nativeKey as birthDate,
    //       but it uses a different jsonSchema.
    _id: VARIABLE_ID__BIRTH_YEAR,
    name: 'Birth year',
    scopeName: '@recipient',
    nativeKey: 'birthDate',
    jsonSchema: {
      type: 'string',
      format: 'year',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Year of birth',
      },
    },
  },
  {
    _id: VARIABLE_ID__NAME,
    name: 'Full name',
    scopeName: '@recipient',
    nativeKey: 'name.text',
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'Full name',
      },
    },
  },
  {
    _id: VARIABLE_ID__NAME_FIRST,
    name: 'First name',
    scopeName: '@recipient',
    nativeKey: 'name.given.0',
    modifiers: [
      // NOTE: Explicitly indicate that this is going to be
      //       an array, or otherwise the update pipeline
      //       will attempt to create an object if the field
      //       does not exist.
      {
        key: 'name.given',
        operator: '$default',
        value: [],
      },
    ],
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'First name',
      },
    },
  },
  {
    _id: VARIABLE_ID__NAME_LAST,
    name: 'Last name',
    scopeName: '@recipient',
    nativeKey: 'name.family',
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'Last name',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE,
    name: 'Phone number (default)',
    scopeName: '@recipient',
    nativeKey: 'phones.0.number',
    evaluated: true, // this is just an accessor property
    jsonSchema: {
      type: 'string',
      format: 'phone',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Default phone number',
      },
    },
  },
  {
    _id: VARIABLE_ID__EMAIL,
    name: 'Email address (default)',
    scopeName: '@recipient',
    nativeKey: 'emails.0.address',
    evaluated: true, // this just just an accessor property
    jsonSchema: {
      type: 'string',
      format: 'email',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Default email address',
      },
    },
  },
  {
    _id: VARIABLE_ID__EMAIL_PERSONAL,
    name: 'Email (Personal)',
    scopeName: '@recipient',
    nativeKey: 'emails.$.address',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_PERSONAL,
      },
    ],
    requiredKeys: ['emails.$.address'],
    modifiers: [
      {
        key: 'emails.$',
        operator: '$default',
        value: {
          type: CONTACT_TYPE_PERSONAL,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'email',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Email (Personal)',
      },
    },
  },
  {
    _id: VARIABLE_ID__EMAIL_WORK,
    name: 'Email (Work)',
    scopeName: '@recipient',
    nativeKey: 'emails.$.address',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_WORK,
      },
    ],
    requiredKeys: ['emails.$.address'],
    modifiers: [
      {
        key: 'emails.$',
        operator: '$default',
        value: {
          type: CONTACT_TYPE_WORK,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'email',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Email (Work)',
      },
    },
  },
  {
    _id: VARIABLE_ID__EMAILS,
    name: 'Emails',
    scopeName: '@recipient',
    nativeKey: 'emails',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'object',
        required: ['address'],
        properties: {
          address: {
            type: 'string',
            minLength: 1,
          },
          type: {
            type: 'string',
            enum: EMAIL_TYPES,
          },
        },
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Emails',
      },
    },
  },
  {
    // TODO: This probably shouldn't be a variable at all.
    _id: VARIABLE_ID__AGE,
    name: 'Age',
    scopeName: '@recipient',
    evaluated: true,
    jsonSchema: {
      type: 'integer',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Age',
      },
    },
  },
  {
    _id: VARIABLE_ID__GENDER,
    name: 'Gender',
    scopeName: '@recipient',
    nativeKey: 'gender',
    jsonSchema: {
      type: 'string',
      enum: [GENDER_MALE, GENDER_FEMALE, GENDER_DIVERSE, GENDER_UNKNOWN],
    },
    jsonSchemaI18n: {
      en: {
        title: 'Gender',
        // TODO: Use this to attach titles to individual choices.
        // oneOf: mapToProperty('title', [
        //   'Male',
        //   'Female',
        //   'Diverse',
        //   'Unknown',
        // ]),
      },
    },
  },
  {
    _id: VARIABLE_ID__GENDER_SINGAPORE,
    name: 'Gender (Singapore)',
    scopeName: '@recipient',
    nativeKey: 'gender',
    jsonSchema: {
      type: 'string',
      enum: [GENDER_MALE, GENDER_FEMALE, GENDER_UNKNOWN],
    },
    jsonSchemaI18n: {
      en: {
        title: 'Gender',
      },
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY,
    name: 'Ethnicity (default)',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Ethnicity',
      },
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_SINGAPORE,
    name: 'Ethnicity (Singapore)',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      title: 'Ethnicity',
      type: 'string',
      enum: ['Chinese', 'Malay', 'Indian', 'Other'],
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_NEW_ZEALAND_AUSTRALIA,
    name: 'Ethnicity (New Zealand/ Australia)',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      title: 'Ethnicity',
      type: 'string',
      enum: [
        'NZ-New Zealand European',
        'NZ-Māori',
        'NZ-Samoan',
        'NZ-Cook Island Maori',
        'NZ-Tongan',
        'NZ-Niuean',
        'NZ-Chinese',
        'NZ-Indian',
        'NZ-Other',
        'AU-Australian',
        'AU-Australian Aboriginal and Torres Strait Islander',
        'AU-Chinese',
        'AU-English',
        'AU-Irish',
        'AU-Italian',
        'AU-Scottish',
        'AU-German',
        'AU-Maori',
        'AU-New Zealand',
        'AU-Other',
      ],
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_NEW_ZEALAND,
    name: 'Ethnicity (New Zealand)',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      title: 'Ethnicity',
      type: 'string',
      enum: [
        'Australian',
        'Australian Aboriginal and Torres Strait Islander',
        'Chinese',
        'English',
        'Irish',
        'Italian',
        'Scottish',
        'German',
        'Maori',
        'New Zealand',
        'Other',
      ],
    },
  },
  {
    _id: VARIABLE_ID__NATIONAL_ID,
    name: 'National health identifier',
    scopeName: '@recipient',
    nativeKey: 'identifiers.$.value',
    nativeKeyFilters: [
      {
        namespace: NAMESPACE_DEFAULT_NHI,
      },
    ],
    requiredKeys: [
      // NOTE: This information should be derived from the corresponding schemata.
      'identifiers.$.value',
    ],
    modifiers: [
      {
        key: 'identifiers.$',
        operator: '$default',
        value: {
          namespace: NAMESPACE_DEFAULT_NHI,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'NHI',
      },
    },
  },
  {
    _id: VARIABLE_ID__IDENTIFIER,
    name: 'Identifier (unspecified)',
    scopeName: '@recipient',
    nativeKey: 'identifiers.$.value',
    nativeKeyFilters: [
      {
        namespace: NAMESPACE_DEFAULT_IDENTIFIER,
      },
    ],
    requiredKeys: [
      // NOTE: This information should be derived from the corresponding schemata.
      'identifiers.$.value',
    ],
    modifiers: [
      {
        key: 'identifiers.$',
        operator: '$default',
        value: {
          namespace: NAMESPACE_DEFAULT_IDENTIFIER,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'Identifier',
      },
    },
  },
  {
    _id: VARIABLE_ID__RECIPIENT_OWNERSHIP,
    name: 'Ownership',
    scopeName: '@recipient',
    nativeKey: 'ownership',
    valueSetter: 'domains',
    valueGetter: 'domains',
    jsonSchema: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        pattern: '\\/$',
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Ownership',
      },
    },
  },
  {
    _id: VARIABLE_ID__IDENTIFIERS,
    name: 'Identifiers',
    scopeName: '@recipient',
    nativeKey: 'identifiers',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'object',
        required: ['value', 'namespace'],
        properties: {
          value: {
            type: 'string',
            minLength: 1,
          },
          namespace: {
            type: 'string',
          },
        },
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Identifiers',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_HOME,
    name: 'Phone (Home)',
    scopeName: '@recipient',
    nativeKey: 'phones.$.number',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_HOME,
      },
    ],
    requiredKeys: ['phones.$.number'],
    modifiers: [
      {
        key: 'phones.$',
        operator: '$default',
        value: {
          type: CONTACT_TYPE_HOME,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'phone',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Phone (Home)',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_MOBILE,
    name: 'Phone (Mobile)',
    scopeName: '@recipient',
    nativeKey: 'phones.$.number',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_MOBILE,
      },
    ],
    requiredKeys: ['phones.$.number'],
    modifiers: [
      {
        key: 'phones.$',
        operator: '$default',
        value: {
          type: CONTACT_TYPE_MOBILE,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'phone',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Phone (Mobile)',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_NOK_MOBILE,
    name: 'Phone (NOK Mobile)',
    scopeName: '@recipient',
    nativeKey: 'phones.$.number',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_NOK_MOBILE,
      },
    ],
    requiredKeys: ['phones.$.number'],
    modifiers: [
      {
        key: 'phones.$',
        operator: '$default',
        value: {
          type: CONTACT_TYPE_NOK_MOBILE,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'phone',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Phone (NOK Mobile)',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_WORK,
    name: 'Phone (Work)',
    scopeName: '@recipient',
    nativeKey: 'phones.$.number',
    nativeKeyFilters: [
      {
        type: CONTACT_TYPE_WORK,
      },
    ],
    requiredKeys: ['phones.$.number'],
    modifiers: [
      {
        key: 'phones.$',
        set: {
          type: CONTACT_TYPE_WORK,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      format: 'phone',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Phone (Work)',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONES,
    name: 'Phones',
    scopeName: '@recipient',
    nativeKey: 'phones',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'object',
        required: ['number'],
        properties: {
          number: {
            type: 'string',
            minLength: 1,
          },
          type: {
            type: 'string',
            enum: PHONE_TYPES,
          },
        },
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Phones',
      },
    },
  },
  {
    _id: VARIABLE_ID__ADM_VISIT_DATE,
    variable: 'Visit Date',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Visit Date',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_1,
    name: 'Meta field 1 (text)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 1',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_2,
    name: 'Meta field 2 (text)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 2',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_3,
    name: 'Meta field 3 (text)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 3',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_1,
    name: 'Meta field 1 (number)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 1',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_2,
    name: 'Meta field 2 (number)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 2',
      },
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_3,
    name: 'Meta field 3 (number)',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Meta 3',
      },
    },
  },
  {
    _id: VARIABLE_ID__NOK_NAME,
    name: 'NOK Name',
    scopeName: '@recipient',
    pii: true,
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Next of Kin Name',
      },
    },
  },
  {
    _id: VARIABLE_ID__NOK_RELATIONSHIP,
    name: 'NOK Relationship',
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Next of Kin Relationship',
      },
    },
  },
  {
    _id: VARIABLE_ID__NOK_NRIC,
    name: 'NOK NRIC',
    scopeName: '@recipient',
    pii: true,
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Next of Kin NRIC',
      },
    },
  },
  {
    _id: VARIABLE_ID__LANGUAGE_PREFERENCE,
    name: 'Language preference',
    scopeName: '@recipient',
    nativeKey: 'languagePreference',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Language preference',
      },
    },
  },

  // MILESTONE
  {
    _id: VARIABLE_ID__MILESTONE_NAME,
    name: 'Name',
    scopeName: '@milestone',
    nativeKey: 'name',
    jsonSchema: {
      type: 'string',
    },
    evaluated: true,
    jsonSchemaI18n: {
      en: {
        title: 'Milestone name',
      },
    },
  },

  // PROJECT
  {
    _id: VARIABLE_ID__PROJECT_NAME,
    name: 'Name',
    scopeName: '@project',
    nativeKey: 'name',
    jsonSchema: {
      type: 'string',
    },
    evaluated: true,
    jsonSchemaI18n: {
      en: {
        title: 'Project name',
      },
    },
  },

  // PARTICIPATION
  {
    _id: VARIABLE_ID__PARTICIPATION_OWNERSHIP,
    name: 'Ownership',
    scopeName: '@participation',
    nativeKey: 'ownership',
    valueSetter: 'domains',
    valueGetter: 'domains',
    jsonSchema: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        pattern: '\\/$',
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Ownership',
      },
    },
  },
  {
    _id: VARIABLE_ID__STUDY_NO,
    name: 'Study number',
    nativeKey: 'studyNo',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'Study No',
      },
    },
  },
  {
    // NOTE: This is an evaluated value (function of studyNo)
    _id: VARIABLE_ID__STUDY_NO_TERMS,
    name: 'Study number (search terms)',
    scopeName: '@participation',
    evaluated: true,
    jsonSchema: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Study No',
      },
    },
  },
  {
    _id: VARIABLE_ID__STATUS_IN_PROJECT,
    name: 'State',
    scopeName: '@participation',
    nativeKey: 'state',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'State',
      },
    },
  },
  {
    _id: VARIABLE_ID__CLINICIAN,
    name: 'Clinician',
    scopeName: '@participation',
    nativeKey: 'clinicianId',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Clinician',
      },
    },
  },
  {
    _id: VARIABLE_ID__CASE_MANAGER,
    name: 'Case manager',
    scopeName: '@participation',
    nativeKey: 'caseManagerId',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Case manger',
      },
    },
  },
  {
    _id: VARIABLE_ID__PATIENT_BASELINE,
    name: 'Baseline',
    scopeName: '@participation',
    nativeKey: 'baseline',
    // NOTE: Baseline is evaluated by looking at trackDate changes in actions array.
    evaluated: true,
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Baseline',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_BED_NUMBER,
    name: 'Expo: Bed number',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Bed number',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_CATEGORY,
    name: 'Expo: Category',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
      enum: ['Category 1', 'Category 2', 'Category 3', 'Category 4'],
    },
    jsonSchemaI18n: {
      en: {
        title: 'Category',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_EMR_SYNC,
    name: 'Expo: EMR Sync',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
      enum: ['Yes', 'No'],
    },
    jsonSchemaI18n: {
      en: {
        title: 'EMR Sync',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_HALL,
    name: 'Expo: Hall',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Hall',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_ROW,
    name: 'Expo: Row',
    scopeName: '@participation',
    jsonSchema: {
      type: 'number',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Row',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_BED,
    name: 'Expo: Bed',
    scopeName: '@participation',
    jsonSchema: {
      type: 'number',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Bed',
      },
    },
  },

  // PARTICIPATION
  {
    _id: VARIABLE_ID__PARTICIPATION_TRACK_ID,
    name: 'Track',
    scopeName: '@participation',
    nativeKey: 'trackId',
    jsonSchema: Id,
    jsonSchemaI18n: {
      en: {
        title: 'Track',
      },
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_TRACK_DATE,
    name: 'Track date',
    scopeName: '@participation',
    nativeKey: 'trackDate',
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Track date',
      },
    },
  },
  // NOTE: This is exactly the same variable as trackDate but
  //       in the ui it will be represented by a different widget.
  {
    _id: VARIABLE_ID__PARTICIPATION_TRACK_DATE_DEDUCED_FROM_MILESTONE_SCHEDULE,
    name: 'Track date deduced from milestone schedule',
    scopeName: '@participation',
    nativeKey: 'trackDate',
    widgetType: VARIABLE_WIDGET_TYPE__RELATIVE_DATE,
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Milestone schedule',
      },
    },
  },

  // ACTIVITY
  {
    _id: VARIABLE_ID__ACTIVITY_STATE,
    name: 'State',
    scopeName: '@activity',
    nativeKey: 'state',
    jsonSchema: {
      type: 'string',
    },
    jsonSchemaI18n: {
      en: {
        title: 'State',
      },
    },
  },
  // TODO: Uncomment the following lines when we are ready to support individual activity ownership.
  //       The current blocker is related to the fact that by default the ownership is inherited
  //       from participation, e.g. there's a hook on Participations which updates corresponding
  //       Activities and AnswersSheets ownership. It's not clear how what should be done
  //       if there's a discrepancy between participation.ownership and activity.ownership.
  // {
  //   _id: VARIABLE_ID__ACTIVITY_OWNERSHIP,
  //   name: 'Ownership',
  //   scopeName: '@activity',
  //   nativeKey: 'ownership',
  //   valueSetter: 'domains',
  //   valueGetter: 'domains',
  //   jsonSchema: {
  //     type: 'array',
  //     minItems: 1,
  //     items: {
  //       type: 'string',
  //       pattern: '\\/$',
  //     },
  //   },
  //   jsonSchemaI18n: {
  //     en: {
  //       title: 'Ownership',
  //     },
  //   },
  // },
  {
    _id: VARIABLE_ID__ACTIVITY_IDENTIFIERS,
    name: 'Identifiers',
    scopeName: '@activity',
    nativeKey: 'identifiers',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'object',
        required: ['value', 'namespace'],
        properties: {
          value: {
            type: 'string',
            minLength: 1,
          },
          namespace: {
            type: 'string',
          },
        },
      },
    },
    jsonSchemaI18n: {
      en: {
        title: 'Identifiers',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_IDENTIFIER,
    name: 'Identifier (unspecified)',
    scopeName: '@activity',
    nativeKey: 'identifiers.$.value',
    nativeKeyFilters: [
      {
        namespace: NAMESPACE_DEFAULT_IDENTIFIER,
      },
    ],
    requiredKeys: [
      // NOTE: This information should be derived from the corresponding schemata.
      'identifiers.$.value',
    ],
    modifiers: [
      {
        key: 'identifiers.$',
        operator: '$default',
        value: {
          namespace: NAMESPACE_DEFAULT_IDENTIFIER,
        },
      },
    ],
    jsonSchema: {
      type: 'string',
      minLength: 1,
    },
    jsonSchemaI18n: {
      en: {
        title: 'Identifier',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_MILESTONE_ID,
    name: 'Milestone',
    scopeName: '@activity',
    nativeKey: 'milestoneId',
    jsonSchema: Id,
    jsonSchemaI18n: {
      en: {
        title: 'Milestone',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_DATE_START,
    name: 'Start date',
    scopeName: '@activity',
    nativeKey: 'dateStart',
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Start date',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_DATE_END,
    name: 'End date',
    scopeName: '@activity',
    nativeKey: 'dateEnd',
    jsonSchema: {
      type: 'string',
      format: 'full-date',
    },
    jsonSchemaI18n: {
      en: {
        title: 'End date',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_TIME_START,
    name: 'Start time',
    scopeName: '@activity',
    nativeKey: 'timeStart',
    jsonSchema: {
      type: 'string',
      format: 'time',
    },
    jsonSchemaI18n: {
      en: {
        title: 'Start time',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_TIME_END,
    name: 'End time',
    scopeName: '@activity',
    nativeKey: 'timeEnd',
    jsonSchema: {
      type: 'string',
      format: 'time',
    },
    jsonSchemaI18n: {
      en: {
        title: 'End time',
      },
    },
  },
];

export const VARIABLES = {};
BUILT_IN_VARIABLES.forEach((variable) => {
  VARIABLES[variable._id] = variable;
});

export const variablesById = VARIABLES;

export const SCOPE_NAMES = [
  '@project',
  '@milestone',
  '@participation',
  '@activity',
  '@recipient',
  '@answersSheet',
];
